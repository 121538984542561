import {
  Box,
  Modal,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  Divider,
  TextField,
  Button,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AuthContext } from 'src/Context/ContextUser';
import FormsProvider from 'src/components/Forms/Provider';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';
import { CustomContainerAtualizar } from 'src/components/CustomContainer/custom-container';
import {
  criarUsuarioCiopaer,
  getUsuarioCiopaer,
} from 'src/services/Operacional/Ciopaer/usuarioCiopaer';
import { schemaUsuarioCiopaer } from './type';

export default function AtualizarServidor({
  open,
  handleClose,
  id,
  Refecth,
}: {
  open: boolean;
  handleClose: () => void;
  id: string;
  Refecth: () => void;
}) {
  const { state } = useContext(AuthContext);

  const {
    data: rows,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['getUsuario', id],
    queryFn: getUsuarioCiopaer,
    enabled: !!id,
  });

  const methods = useForm({
    resolver: yupResolver(schemaUsuarioCiopaer),
    defaultValues: rows,
  });

  const { reset } = methods;

  useEffect(() => {
    if (rows) {
      reset(rows);
    }
  }, [rows, reset]);

  const { mutate, isPending } = useMutation({
    mutationFn: criarUsuarioCiopaer,
    onSuccess: () => {
      ToastFun('Usuário atualizado com sucesso!', 'success');
      handleClose();
      Refecth();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message || 'Erro ao atualizar usuário.', 'error');
    },
  });

  const onSubmit = async (data: any) => {
    mutate({ ...data, usuarioId: id, atualizadoEm: new Date() });
  };

  const funcoesCiopaer = [
    'Aux Mêcanico',
    'Diretor',
    'OAT',
    'OSM/Enfermeiro',
    'OSM/Médico',
    'PIC Helicóptero',
    'SIC Helicóptero',
    'PIC Avião',
    'SIC Avião',
    'Serviço Administrativo',
    'TASA/ Apoio Solo',
  ];

  if (isLoading) {
    return <Typography>Carregando...</Typography>;
  }

  if (isError) {
    return <Typography>Erro ao carregar dados do usuário.</Typography>;
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 3,
          width: { xl: '58%', md: '75%', sm: '70%' },
          maxHeight: '95%',
          overflow: 'auto',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="Fechar" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <CustomContainerAtualizar title="Atualizar Servidor">
              <Box p={2}>
                <FormsProvider methods={methods} handleSubmit={onSubmit}>
                  <Grid container spacing={2} sx={{ color: '#555555', p: 2 }}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        type="text"
                        name="nome"
                        label="Nome"
                        disabled
                        fullWidth
                        value={rows?.nome || ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={{ my: 1 }}>
                        Dados Complementares
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">Ocupação</Typography>
                      <TextFieldRG select name="funcao" label="">
                        {funcoesCiopaer.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextFieldRG>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">Nome de Guerra</Typography>
                      <TextFieldRG name="nomeGuerra" label="" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">Código Anac</Typography>
                      <TextFieldRGMaks name="codigoAnac" mask="______" label="" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">Horas Iniciais</Typography>
                      <TextFieldRGMaks
                        name="horasInicias"
                        mask="______._"
                        maskType="number"
                        label=""
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Button
                        variant="contained"
                        color="success"
                        type="submit"
                        startIcon={<CachedIcon />}
                      >
                        Atualizar
                      </Button>
                    </Grid>
                  </Grid>
                </FormsProvider>
              </Box>
            </CustomContainerAtualizar>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
