/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useMutation, useQuery } from '@tanstack/react-query';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { AutorizarEscala, getListarEscalas } from 'src/services/Operacional/Ciopaer/escala';
import { tratandoData } from 'src/config/utils';
import { AuthContext } from 'src/Context/ContextUser';
import ModalAutorizar from 'src/components/Modal/Autorizar';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import CadastroEscala from './Cadastro';
import { VisualizarEscala } from './Visualizar';

export function Escalas() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const { state } = useContext(AuthContext);
  const [modAutorizacao, setModAutorizacao] = useState(false);

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const {
    data: rows,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getEscalar', skip, take, search],
    queryFn: getListarEscalas,
  });
  const { mutate } = useMutation({
    mutationFn: AutorizarEscala,
    onSuccess: (data) => {
      ToastFun('Rendimento autorizado com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const handleButtonClick = (idEscala: string, type: string) => {
    setId(idEscala);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  useEffect(() => {
    if (
      (state.usuario && state.usuario?.permissoes.includes('*')) ||
      state.usuario?.permissoes.includes('OPERACIONAL_CIOPAER_*')
    ) {
      setModAutorizacao(true);
    }
  }, [state.usuario]);
  const columns: GridColDef[] = [
    {
      field: 'dataEscala',
      headerName: 'DATA DA ESCALA',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => tratandoData(params.row.dataEscala),
    },

    {
      field: 'criadoEm',
      headerName: 'CRIADO EM ',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'usuarioCreated',
      headerName: 'CRIADO POR ',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            disabled={params.row.status === 'Autorizado' || !modAutorizacao}
            color="warning"
            onClick={() => handleButtonClick(params.row.id, 'Editar')}
          >
            <ModeEditIcon />
          </IconButton>
          <IconButton
            disabled={params.row.status === 'Autorizado' || !modAutorizacao}
            color="success"
            onClick={() => handleButtonClick(params.row.id, 'Autorizar')}
          >
            <CheckCircleIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box
      sx={{
        m: 'auto',
      }}
    >
      <Stack>
        <CustomBreadcrumbs
          heading="Centro Integrado de Operações Aéreas"
          links={[{ name: '', href: '/operacional/ciopaer' }]}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Escala
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Escalas">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
        {modalType === 'Cadastro' && (
          <CadastroEscala open={isModalOpen} handleClose={handleCloseModal} refetch={refetch} />
        )}
        {modalType === 'Visualizar' && id && (
          <VisualizarEscala
            open={isModalOpen}
            handleClose={handleCloseModal}
            refetch={refetch}
            id={id}
          />
        )}
        {id && modalType === 'Autorizar' && (
          <ModalAutorizar
            isOpen={isModalOpen}
            nomeAcao="Escala"
            id={id}
            mutate={mutate}
            handleClose={handleCloseModal}
          />
        )}
      </Stack>
    </Box>
  );
}
