import {
  Box,
  Modal,
  Grid,
  IconButton,
  Typography,
  TableCell,
  styled,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getUsuarioCiopaer } from 'src/services/Operacional/Ciopaer/usuarioCiopaer';
import { useQuery } from '@tanstack/react-query';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));
export default function VisualizarServidor({
  open,
  handleClose,
  id,
}: {
  open: boolean;
  handleClose: () => void;
  id: string;
}) {
  const {
    data: rows,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['getUsuario', id],
    queryFn: getUsuarioCiopaer,
    enabled: !!id,
  });

  if (isLoading) {
    return <Typography>Carregando...</Typography>;
  }
  console.log(rows);
  if (isError) {
    return <Typography>Erro ao carregar dados do usuário.</Typography>;
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          py: 2,
          px: 3,
          width: {
            xl: '70%',
            md: '85%',
            sm: '70%',
          },
          maxHeight: '650px',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right', my: 2.5 }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <CustomContainerList type="detalhes" title="Servidor" shadow>
              <TableContainer>
                <Table sx={{ minWidth: 700, mt: 2 }}>
                  <TableHead sx={{ background: '#f5f5f5' }}>
                    <TableRow>
                      <TableCellHead>Nome Completo</TableCellHead>
                      <TableCellHead>email</TableCellHead>
                      <TableCellHead align="center">função</TableCellHead>
                      <TableCellHead align="center">Situação</TableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCellBody>{rows.nome}</TableCellBody>
                      <TableCellBody>{rows.email}</TableCellBody>
                      <TableCellBody align="center">{rows.funcao}</TableCellBody>
                      <TableCellBody align="center">
                        <Chip
                          label={rows.status}
                          sx={{ color: 'white' }}
                          color={rows.status === 'Ativo' ? 'success' : 'warning'}
                        />
                      </TableCellBody>
                    </TableRow>
                  </TableBody>
                  <TableHead sx={{ background: '#f5f5f5' }}>
                    <TableRow>
                      <TableCellHead>nome Guerra</TableCellHead>
                      <TableCellHead>código Anac</TableCellHead>
                      <TableCellHead align="center">horas inicias</TableCellHead>
                      <TableCellHead align="center">quantidade de voos</TableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCellBody>{rows.nomeGuerra}</TableCellBody>
                      <TableCellBody>{rows.codigoAnac}</TableCellBody>
                      <TableCellBody align="center">{rows.horasInicias}</TableCellBody>
                      <TableCellBody align="center">{rows.countVoos}</TableCellBody>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomContainerList>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
