import { useState } from 'react';
import { Alert, Box, Grid, IconButton, Modal, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import FormsProvider from 'src/components/Forms/Provider';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { postCadastrarUsuario } from 'src/services/Admin/post';
import { schemaUsuario, dadosUsuario } from 'src/pages/Admin/Usuarios/typeUsuario';
import { ErroResponse } from 'src/pages/types';
import { useNavigate } from 'react-router-dom';
import FormularioUsuario from '../formularioUsuario';

export default function CadastroUsuario({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaUsuario),
  });

  const {
    setValue,
    unregister,
    watch,
    reset,
    formState: { errors },
  } = methods;
  const teste = watch();
  console.log(teste);
  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarUsuario,
    onSuccess: (data) => {
      ToastFun('Usuário Cadastrado com sucesso', 'success');

      handleClose();
      reset();
    },

    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const onSubmit = async (data: dadosUsuario) => {
    const permission: object[] = [];
    console.log(data);

    data.permissoes = data.permissoes.filter((funcao: any) => funcao !== undefined);
    data.permissoes.forEach((item) => {
      let permiAdmin = {};
      let permiOther = {};
      if (item.funcao === 'admin') {
        permiAdmin = { nome: 'admin' };
      } else {
        permiOther = { nome: `${item.funcao}_${item.modulo}` };
      }
      if (Object.keys(permiAdmin).length) {
        permission.push(permiAdmin);
      } else if (Object.keys(permiOther).length) {
        permission.push(permiOther);
      }
    });
    data.permissoesUsuario = permission;
    mutate(data);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '650px',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right', mt: -4 }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Usuário - Cadastro
            </Alert>
          </Grid>
          <Grid md={12} xs={12} p={3}>
            <FormsProvider methods={methods} handleSubmit={onSubmit}>
              <FormularioUsuario setValue={setValue} unregister={unregister} />
            </FormsProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
