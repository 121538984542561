import { Box, Modal, Grid, IconButton, Alert, MenuItem, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { useMutation, useQuery } from '@tanstack/react-query';
import { postCadastrarMetaGeral } from 'src/services/Planejamento/FundoNacional/metas';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import FormsProvider from 'src/components/Forms/Provider';
import {
  criarAbastecimentos,
  getAbastecimento,
} from 'src/services/Operacional/Ciopaer/abastecimentos';
import { yupResolver } from '@hookform/resolvers/yup';
import { tratandoDataInput } from 'src/config/utils';
import AbastecimentoForms from '../Formulario/abastecimento';
import { Abastecimento, dataAbastecimento, schemaAbastecimento } from './type';

export default function CadastroAbastecimento({
  open,
  handleClose,
  id,
  RefecthAbastecimento,
}: {
  open: boolean;
  handleClose: any;
  id?: string;
  RefecthAbastecimento: any;
}) {
  const { data: rows } = useQuery({
    queryKey: ['getAbastecimento', id],
    queryFn: getAbastecimento,
    enabled: !!id,
  });
  console.log(rows);

  const methods = useForm({
    resolver: yupResolver(schemaAbastecimento),
    defaultValues: {
      empresaId: rows?.empresaId || '',
      combustivelId: rows?.combustivelId || '',
      aeronaveId: rows?.aeronaveId || '',
      ...rows,
    },
  });

  const { state } = useContext(AuthContext);
  const {
    watch,
    reset,
    formState: { errors },
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: criarAbastecimentos,
    onSuccess: (data) => {
      ToastFun('Abastecimento Cadastrado com sucesso', 'success');
      handleClose();
      reset();
      RefecthAbastecimento();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const onSubmit = async (data: Abastecimento) => {
    data.usuarioCreatedId = state.usuario?.id!;
    mutate(data);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Abastecimento - Cadastro
            </Alert>
            <FormsProvider methods={methods} handleSubmit={onSubmit}>
              <AbastecimentoForms />
            </FormsProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
