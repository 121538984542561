import { Grid, Button, MenuItem } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';

export default function OcorrenciaForms() {
  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={12}>
        <TextFieldRG select label="Aeronave" name="aeronave">
          <MenuItem value="aeroMedicoBombeiro">Aeromédico/Bombeiro</MenuItem>
          <MenuItem value="policial">Policial</MenuItem>
          <MenuItem value="vip">VIP</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG select label="Comandante" name="comandante">
          <MenuItem value="ativo">Ativo</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG select label="Copiloto" name="copiloto">
          <MenuItem value="ativo">Ativo</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG select label="Operadores" name="operadores">
          <MenuItem value="ativo">Ativo</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG select label="Base" name="base">
          <MenuItem value="ativo">Ativo</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG select label="Cidade da Ocorrência" name="cidade">
          <MenuItem value="ativo">Ativo</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG select label="Tipo" name="tipo">
          <MenuItem value="ativo">RESGATE</MenuItem>
          <MenuItem value="inativo">POLICIAL</MenuItem>
          <MenuItem value="inativo">FISCALIZAÇÃO</MenuItem>
          <MenuItem value="inativo">VIP</MenuItem>
          <MenuItem value="inativo">INSTRUÇÃO</MenuItem>
          <MenuItem value="inativo">MANUTENÇÃO</MenuItem>
          <MenuItem value="inativo">SEJUSP</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG select label="Natureza" name="natureza">
          <MenuItem value="ativo">Ativo</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG label="Data" name="prefixo" type="date" />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG label="Horimetro Inicial" name="apelido" />
      </Grid>
      <Grid item md={3} xs={12}>
        <TextFieldRG label="Horimetro Final" name="fabricante" />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextFieldRG multiline rows={4} label="Histórico" name="fabricante" />
      </Grid>

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
