import { Box, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import AddIcon from '@mui/icons-material/Add';
import SelectMultiple from 'src/components/Forms/TextFieldRG/SelectInput';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const SelectServidor = ({
  label,
  name,
  servidores,
}: {
  label: string;
  name: string;
  servidores: any[];
}) => (
  <TextFieldRG select label={label} name={name}>
    {servidores.map((servidor) => (
      <MenuItem key={servidor.id} value={servidor.id}>
        {servidor.nome} - {servidor.funcao}
      </MenuItem>
    ))}
  </TextFieldRG>
);

const TimeField = ({ label, name }: { label: string; name: string }) => (
  <TextFieldRG label={label} name={name} type="time" />
);

export const AsaRotativaForm = ({ rows }: { rows: any }) => (
  <Grid container spacing={2}>
    <Grid item xs={4}>
      <SelectServidor
        label="Comandante de Aeronave"
        name="AsaRotativa.comandanteAeronaveId"
        servidores={rows?.results || []}
      />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Entrada" name="AsaRotativa.horarioEntradaComandanteAeronave" />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Saída" name="AsaRotativa.horarioSaidaComandanteAeronave" />
    </Grid>
    <Grid item xs={4}>
      <SelectServidor
        label="Comandante de Op. Aéreas"
        name="AsaRotativa.comandanteOpAereaId"
        servidores={rows?.results || []}
      />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Entrada" name="AsaRotativa.horarioEntradaComandanteOpAereas" />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Saída" name="AsaRotativa.horarioSaidaComandanteOpAereas" />
    </Grid>
    <Grid item xs={4}>
      <SelectMultiple
        label="Operador Aerotático"
        key="AsaRotativa.Operadores.servidores"
        name="AsaRotativa.Operadores.servidores"
        options={rows?.results}
        fullWidth
      />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Entrada" name="AsaRotativa.Operadores.horarioEntrada" />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Saída" name="AsaRotativa.Operadores.horarioSaida" />
    </Grid>
  </Grid>
);
export const AsaFixaForm = ({ rows }: { rows: any }) => (
  <Grid container spacing={2}>
    <Grid item xs={4}>
      <SelectServidor
        label="Comandante de Aeronave"
        name="AsaFixa.comandanteAeronaveId"
        servidores={rows?.results || []}
      />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Entrada" name="AsaFixa.horarioEntradaComandanteAeronave" />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Saída" name="AsaFixa.horarioSaidaComandanteAeronave" />
    </Grid>
    <Grid item xs={4}>
      <SelectServidor
        label="Comandante de Op. Aéreas"
        name="AsaFixa.comandanteOpAereaId"
        servidores={rows?.results || []}
      />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Entrada" name="AsaFixa.horarioEntradaComandanteOpAereas" />
    </Grid>
    <Grid item xs={4}>
      <TimeField label="Horário de Saída" name="AsaFixa.horarioSaidaComandanteOpAereas" />
    </Grid>
    <Grid item xs={4}>
      <SelectMultiple
        label="Operador Aerotático"
        key="AsaFixa.Operadores.servidores"
        name="AsaFixa.Operadores.servidores"
        options={rows?.results}
        fullWidth
      />
    </Grid>
    <Grid item xs={4}>
      <TimeField
        label="Horário de Entrada"
        name="AsaFixa.Operadores.horarioEntrada"
        key="AsaFixa.Operadores.horarioEntrada"
      />
    </Grid>
    <Grid item xs={4}>
      <TimeField
        label="Horário de Saída"
        name="AsaFixa.Operadores.horarioSaida"
        key="AsaFixa.Operadores.horarioSaida"
      />
    </Grid>
  </Grid>
);
export const AdministracaoForm = ({ rows }: { rows: any }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box px={1} my={1} sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}>
        <Typography variant="subtitle2" sx={{ py: 1 }}>
          Asas Rotativas
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <SelectMultiple
        label="Servidores"
        key="AsaRotativa.Administracao.servidores"
        name="AsaRotativa.Administracao.servidores"
        options={rows?.results}
        fullWidth
      />
    </Grid>
    <Grid item xs={3}>
      <TimeField label="Horário de Entrada" name="AsaRotativa.Administracao.horarioEntrada" />
    </Grid>
    <Grid item xs={3}>
      <TimeField label="Horário de Saída" name="AsaRotativa.Administracao.horarioSaida" />
    </Grid>
    <Grid item xs={12}>
      <Box px={1} my={1} sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}>
        <Typography variant="subtitle2" sx={{ py: 1 }}>
          Asa Fixa
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <SelectMultiple
        label="Servidores"
        key="AsaFixa.Administracao.servidores"
        name="AsaFixa.Administracao.servidores"
        options={rows?.results}
        fullWidth
      />
    </Grid>
    <Grid item xs={3}>
      <TimeField label="Horário de Entrada" name="AsaFixa.Administracao.horarioEntrada" />
    </Grid>
    <Grid item xs={3}>
      <TimeField label="Horário de Saída" name="AsaFixa.Administracao.horarioSaida" />
    </Grid>
  </Grid>
);
export const ManutencaoForm = ({ rows }: { rows: any }) => {
  const [servidores, setServidores] = useState([
    { id: Date.now(), name: '', entrada: '', saida: '' },
  ]);

  const handleAddServidor = () => {
    setServidores((prev) => [...prev, { id: Date.now(), name: '', entrada: '', saida: '' }]);
  };

  const handleRemoveServidor = (id: number) => {
    setServidores((prev) => prev.filter((servidor) => servidor.id !== id));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          px={1}
          my={1}
          sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}
        >
          <Typography variant="subtitle2" sx={{ py: 1 }}>
            Permanência no Hangar
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <SelectServidor
          label="Servidor"
          name="PermanenciaHangar.servidorId"
          servidores={rows?.results || []}
        />
      </Grid>
      <Grid item xs={4}>
        <TimeField label="Horário de Entrada" name="PermanenciaHangar.horarioEntrada" />
      </Grid>
      <Grid item xs={4}>
        <TimeField label="Horário de Saída" name="PermanenciaHangar.horarioSaida" />
      </Grid>
      <Grid item xs={12}>
        <Box
          px={1}
          my={1}
          sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}
        >
          <Grid container>
            <Grid item md={6} xs={10}>
              <Typography variant="subtitle2" sx={{ py: 1 }}>
                Manutenção
              </Typography>
            </Grid>
            <Grid item md={6} xs={2} sx={{ textAlign: 'right' }}>
              <IconButton onClick={handleAddServidor}>
                <AddIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {servidores.map((servidor, index) => (
        <React.Fragment key={servidor.id}>
          <Grid item xs={4}>
            <SelectServidor
              label={`Servidor ${index + 1}`}
              name={`Manutencao.${index}.servidorId`}
              servidores={rows?.results || []}
            />
          </Grid>
          <Grid item xs={3.5}>
            <TimeField label="Horário de Entrada" name={`Manutencao.${index}.horarioEntrada`} />
          </Grid>
          <Grid item xs={3.5}>
            <TimeField label="Horário de Saída" name={`Manutencao.${index}.horarioSaida`} />
          </Grid>
          <Grid item xs={1} mt={3} sx={{ textAlign: 'right' }}>
            <IconButton onClick={() => handleRemoveServidor(servidor.id)}>
              <DeleteIcon color="error" />
            </IconButton>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
