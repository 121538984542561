import { Api } from 'src/services/Api';
import Endpoints from 'src/services/endpoints';

export async function getListarEventos({ queryKey }: { queryKey: any }) {
  const [_key, dataInicial, dataFinal, id] = queryKey;

  const response = await Api.get(Endpoints.eventosCiopaer, {
    params: { dataInicial, dataFinal, id },
  });
  return response.data;
}
