import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupsIcon from '@mui/icons-material/Groups';
import CommuteIcon from '@mui/icons-material/Commute';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const PivateModules = [
  {
    title: 'Administrativo',
    link: '/administrativo',
    icon: <WorkOutlineOutlinedIcon fontSize="large" />,
    permissao: 'ADMINISTRATIVO',
  },
  {
    title: 'Operacional',
    link: '/operacional',
    icon: <CommuteIcon fontSize="large" />,
    permissao: 'OPERACIONAL',
  },

  {
    title: 'Planejamento',
    link: '/planejamento',
    icon: <FolderOpenOutlinedIcon fontSize="large" />,
    permissao: 'PLANEJAMENTO',
  },
  {
    title: 'Tecnologia',
    link: '/tecnologia',
    icon: <DevicesOutlinedIcon fontSize="large" />,
    permissao: 'TIC',
  },
  {
    title: 'Apoio Biopsicossocial',
    link: '/ciab',
    icon: <Diversity1OutlinedIcon fontSize="large" />,
    permissao: 'CIAB',
  },
  {
    title: 'Administrador',
    link: '/administrador',
    icon: <AdminPanelSettingsIcon fontSize="large" />,
    permissao: 'admin',
  },
  {
    title: 'Agendamento',
    link: '/agendamento',
    icon: <EventAvailableOutlinedIcon fontSize="large" />,
    permissao: 'admin',
  },
];

export const PublicsModules = [
  //   {
  //     title: 'Agendamento',
  //     link: '/agendamento',
  //     icon: <EventAvailableOutlinedIcon fontSize="large" />,
  //     permissao: 'admin',
  //   },
];
