const Endpoints = {
  auth: '/auth/login',
  eixos: '/eixo',
  usuario: '/usuario',
  unidade: '/unidade',
  grupoPermissoes: '/grupo-de-usuarios',
  instituicao: '/instituicao',
  eixoFinanciado: '/eixo-financiado',
  metaGeral: '/meta-geral',
  metaFisica: '/meta-fisica',
  metaInstituicao: '/meta-instituicao',
  metaFisicaValor: 'meta-fisica/metas-fisicas-valor',
  metaFisicaRemanejamento: 'meta-fisica/metas-fisicas-remanejamento',
  usuarioPermissao: '/usuario/permissao',
  planoAplicacao: '/plano-aplicacao',
  tipoDespesa: '/tipo-despesa',
  rendimento: '/rendimento',
  remanejamento: '/remanejamento',
  planoAplicacaoEixos: '/plano-aplicacao/eixos',
  planoAplicacaoExercicioEixos: '/plano-aplicacao/exercicio-eixo',
  planoAplicacaoEixosFinanciados: '/plano-aplicacao/eixos-financiados',
  flagsPermissoes: '/usuario/flasgPermissoes',
  empenhos: '/empenhos',
  despesas: '/despesas',

  // OPERACIONAL
  usuarioCiopaer: '/usuarios-ciopaer',
  aeronaves: '/aeronaves',
  abastecimento: '/abastecimento',
  escalaCiopaer: '/escala-ciopaer',
  eventosCiopaer: '/eventos-ciopaer',
};

export default Endpoints;
