import { Api } from 'src/services/Api';
import Endpoints from 'src/services/endpoints';

// GET
export async function getUsuariosCiopaer({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.usuarioCiopaer, {
    params: {
      skip,
      take,
      filters,
    },
  });
  return response.data;
}
export async function getUsuarioCiopaer({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.usuarioCiopaer}/${id}`);
  return response.data;
}

export async function criarUsuarioCiopaer(dadosUsuario: any) {
  const response = await Api.post(Endpoints.usuarioCiopaer, {
    ...dadosUsuario,
  });
  return response.data;
}
