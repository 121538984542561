// components/CustomTableCell.tsx
import { styled, TableCell, TableCellProps } from '@mui/material';

export const TableCellHead = styled((props: TableCellProps) => <TableCell {...props} />)(
  ({ theme }) => ({
    fontSize: '11px',
    fontWeight: 700,
    color: '#082765',
    textTransform: 'uppercase',
    textAlign: 'center',
  })
);

export const TableCellBody = styled((props: TableCellProps) => <TableCell {...props} />)(
  ({ theme }) => ({
    fontSize: '14px',
    color: '#555555',
    textTransform: 'uppercase',
  })
);
