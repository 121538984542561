import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Chip, Modal, Alert, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from '@tanstack/react-query';
import { CalculandoPorcentagem } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getMetaGeral } from 'src/services/Planejamento/FundoNacional/metas';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaGeralVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: any;
}) {
  const include = ['eixoFinanciado', 'user', 'planoAplicacao', 'metasFisicas'];
  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getMetasGerais', id, include],
    queryFn: getMetaGeral,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  function CalculandoPorcetagemGeral(metasInstituicoes: any[]) {
    const novosValores = metasInstituicoes.reduce(
      (acc: any, item: any) => ({
        valorTotalInicial: acc.valorTotalInicial + item.valorInicial,
        valorTotalEmpenhado: acc.valorTotalEmpenhado + item.valorEmpenhado,
        valorTotalExecutado: acc.valorTotalExecutado + item.valorExecutado,
        valorTotalSaldoEstimativo: acc.valorTotalSaldoEstimativo + item.saldoEstimativo,
        valorTotalSaldoReal: acc.valorTotalSaldoReal + item.saldoReal,
      }),
      {
        valorTotalInicial: 0,
        valorTotalEmpenhado: 0,
        valorTotalExecutado: 0,
        valorTotalSaldoEstimativo: 0,
        valorTotalSaldoReal: 0,
      }
    );

    return CalculandoPorcentagem(
      novosValores.valorTotalExecutado,
      novosValores.valorTotalExecutado + novosValores.valorTotalSaldoReal
    );
  }

  return (
    <div>
      {!isPending && rowsMeta && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              py: 2,
              px: 3,
              width: {
                xl: '70%',
                md: '85%',
                sm: '70%',
              },
              maxHeight: '650px',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container>
              <Grid md={12} xs={12} sx={{ textAlign: 'right', my: 2.5 }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid md={12} xs={12}>
                <CustomContainerList type="detalhes" title="Meta Geral" shadow>
                  <TableContainer>
                    <Table sx={{ minWidth: 700, mt: 2 }}>
                      <TableHead sx={{ background: '#f5f5f5' }}>
                        <TableRow>
                          <TableCellHead align="center">Exercicío Orçamentário</TableCellHead>
                          <TableCellHead>Eixo</TableCellHead>
                          <TableCellHead colSpan={2}>NOME</TableCellHead>

                          <TableCellHead align="center">Situação</TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCellBody align="center">
                            {rowsMeta.planoAplicacao.exercicioOrcamentario}
                          </TableCellBody>
                          <TableCellBody>{rowsMeta.eixoFinanciado.eixoNome}</TableCellBody>
                          <TableCellBody colSpan={2}>{rowsMeta.nome}</TableCellBody>

                          <TableCellBody align="center">
                            <Chip
                              label={rowsMeta.status}
                              sx={{ color: 'white' }}
                              color={rowsMeta.status === 'Pendente' ? 'warning' : 'success'}
                            />
                          </TableCellBody>
                        </TableRow>
                        <TableRow sx={{ background: '#f5f5f5' }}>
                          <TableCellHead colSpan={3}>Meta Física</TableCellHead>
                          <TableCellHead align="center">Natureza</TableCellHead>
                          <TableCellHead align="center">% Execução</TableCellHead>
                        </TableRow>
                        {rowsMeta.metasFisicas.map((item: any, index: number) => (
                          <TableRow key={index}>
                            <TableCellBody colSpan={3}>
                              {index + 1} - {item.nome}
                            </TableCellBody>
                            <TableCellBody align="center">{item.natureza}</TableCellBody>
                            <TableCellBody align="center">
                              {CalculandoPorcetagemGeral(item.metasInstituicoes)} %
                            </TableCellBody>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomContainerList>
              </Grid>
              {rowsMeta.status === 'Autorizado' && (
                <Box my={2}>
                  <Alert severity="info">
                    Autorizado por : {rowsMeta.usuarioAuthorization.nome}
                  </Alert>
                </Box>
              )}
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
}
