import { Grid, Button, MenuItem } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';

export default function AeronaveForms({ id }: { id?: any }) {
  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <TextFieldRG select label="Tipo Uso" name="tipoUso">
          <MenuItem value="aeroMedicoBombeiro">Aeromédico/Bombeiro</MenuItem>
          <MenuItem value="policial">Policial</MenuItem>
          <MenuItem value="vip">VIP</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG select label="Situação" name="status">
          <MenuItem value="ativo">Ativo</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRGMaks label="Prefixo" name="prefixo" mask="__-___" maskType="text" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Apelido" name="apelido" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Fabricante" name="fabricante" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Modelo" name="modelo" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Nº Série" name="numeroSerie" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRGMaks label="Ano de Fabricação" name="anoFabricacao" mask="____" />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRGMaks
          name="horasInicias"
          mask="______._"
          maskType="number"
          label="Horas Inicias"
          disabled={id}
        />
      </Grid>

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          {id ? 'Atualizar' : 'Cadastrar'}
        </Button>
      </Grid>
    </Grid>
  );
}
