import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow, Badge } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from '@tanstack/react-query';
import { getUsuariosCiopaer } from 'src/services/Operacional/Ciopaer/usuarioCiopaer';
import AtualizarServidor from './Atualizar';
import VisualizarServidor from './visualizarServidor';

export default function Servidores() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const { data: rows, refetch } = useQuery({
    queryKey: ['getUsuario', skip, take, search],
    queryFn: getUsuariosCiopaer,
  });

  const handleButtonClick = (idUser: string, type: string) => {
    setId(idUser);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'NOME',
      minWidth: 150,
      flex: 2,
    },
    {
      field: 'funcao',
      headerName: 'FUNÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'horasInicias',
      headerName: 'HORAS DE VÔO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'countVoos',
      headerName: 'QUANTIDADE DE VOOS',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Inativa' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => (
        <Box>
          <IconButton color="primary">
            <VisibilityIcon onClick={() => handleButtonClick(params.row.id, 'Visualizar')} />
          </IconButton>

          <IconButton
            color="warning"
            onClick={() => handleButtonClick(params.row.id, 'Editar')}
            disabled={params.row.funcao}
          >
            <ModeEditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack>
        <CustomBreadcrumbs
          heading="Centro Integrado de Operações Aéreas"
          links={[{ name: '', href: '/operacional/ciopaer' }]}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Servidores">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
        {id && modalType === 'Editar' && (
          <AtualizarServidor
            open={isModalOpen}
            handleClose={handleCloseModal}
            id={id}
            Refecth={refetch}
          />
        )}
        {id && modalType === 'Visualizar' && (
          <VisualizarServidor open={isModalOpen} handleClose={handleCloseModal} id={id} />
        )}
      </Stack>
    </Box>
  );
}
