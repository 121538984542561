import { Box, Modal, Grid, IconButton, Alert, MenuItem, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { criarAeronave } from 'src/services/Operacional/Ciopaer/aeronave';
import { criarEscala } from 'src/services/Operacional/Ciopaer/escala';
import EscalaFormulario from '../Formulario';
import { Escala, schemaEscala } from '../Formulario/type';

export default function CadastroEscala({
  open,
  handleClose,
  refetch,
}: {
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
}) {
  const methods = useForm({ resolver: yupResolver(schemaEscala) });

  const { state } = useContext(AuthContext);
  const {
    reset,
    formState: { errors },
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: criarEscala,
    onSuccess: (data) => {
      ToastFun('Escala criada com sucesso', 'success');
      handleClose();
      reset();
      refetch();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  const onSubmit = async (data: Escala) => {
    data.dadosEscala.usuarioCreatedId = state.usuario?.id!;
    mutate(data);
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        reset();
      }}
    >
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                handleClose();
                reset();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Escala - Cadastro
            </Alert>
            <FormsProvider methods={methods} handleSubmit={onSubmit}>
              <EscalaFormulario />
            </FormsProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
