import { Grid, Button, MenuItem, Box, IconButton, TextField } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAeronave, getAeronaves } from 'src/services/Operacional/Ciopaer/aeronave';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import {
  criarCombustivelAbastecimentos,
  criarEmpresaAbastecimentos,
  getCombustiveisAbastecimentos,
  getEmpresasAbastecimentos,
} from 'src/services/Operacional/Ciopaer/abastecimentos';
import { useWatch } from 'react-hook-form';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';

export default function AbastecimentoForms() {
  const [newEmpresa, setNewEmpresa] = useState({});
  const [addEmpresa, setAddEmpresa] = useState<boolean>(false);
  const [newCombustivel, setNewCombustivel] = useState({});
  const [addCombustivel, setAddCombustivel] = useState<boolean>(false);

  const empresaId = useWatch({ name: 'empresaId' });

  const { data: empresas, refetch } = useQuery({
    queryKey: ['getEmpresas'],
    queryFn: getEmpresasAbastecimentos,
  });
  const { data: aeronaves, isPending } = useQuery({
    queryKey: ['getMetas'],
    queryFn: getAeronaves,
  });
  const { data: combustivel, refetch: refetchCombustivel } = useQuery({
    queryKey: ['getCombustivel', empresaId],
    queryFn: getCombustiveisAbastecimentos,
    enabled: !!empresaId,
  });

  const { mutate: empresaAbastecimento } = useMutation({
    mutationFn: criarEmpresaAbastecimentos,
    onSuccess: (data) => {
      ToastFun('Empresa cadastra com sucesso', 'success');
      setAddEmpresa(false);
      refetch();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const { mutate: combustivelAbastecimento } = useMutation({
    mutationFn: criarCombustivelAbastecimentos,
    onSuccess: (data) => {
      ToastFun('Combustivel cadastra com sucesso', 'success');
      setAddCombustivel(false);
      refetchCombustivel();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  return (
    <Grid container spacing={2}>
      {!addEmpresa && (
        <Grid item md={4} xs={12}>
          <TextFieldRG select label="Empresa" name="empresaId">
            {empresas?.results.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextFieldRG>
          <Box sx={{ mt: -2, textAlign: 'right' }}>
            <IconButton color="info" onClick={() => setAddEmpresa(true)}>
              <AddCircleIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>
        </Grid>
      )}
      {addEmpresa && (
        <Grid item md={4} xs={12}>
          <TextField
            label="Empresa"
            name="empresa"
            fullWidth
            sx={{ my: 2 }}
            onChange={(e) => setNewEmpresa({ ...newEmpresa, nome: e.target.value })}
          />

          <Box sx={{ mt: -2, textAlign: 'right' }}>
            <IconButton
              color="info"
              onClick={() =>
                newEmpresa
                  ? empresaAbastecimento(newEmpresa)
                  : ToastFun('Digite o Nome da Empresa', 'success')
              }
            >
              <SaveIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>
        </Grid>
      )}

      {/* buscar combustivel */}

      {!addCombustivel && (
        <Grid item md={4} xs={12}>
          <TextFieldRG select label="Combustivel" name="combustivelId">
            {combustivel?.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextFieldRG>
          <Box sx={{ mt: -2, textAlign: 'right' }}>
            <IconButton disabled={!empresaId} color="info" onClick={() => setAddCombustivel(true)}>
              <AddCircleIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>
        </Grid>
      )}
      {addCombustivel && (
        <Grid item md={4} xs={12}>
          <TextField
            label="Combustivel"
            name="Combustivel"
            fullWidth
            sx={{ my: 2 }}
            onChange={(e) =>
              setNewCombustivel({ ...newCombustivel, nome: e.target.value, empresaId })
            }
          />

          <Box sx={{ mt: -2, textAlign: 'right' }}>
            <IconButton
              color="info"
              onClick={() =>
                newCombustivel
                  ? combustivelAbastecimento(newCombustivel)
                  : ToastFun('Digite o Nome da Combustivel', 'success')
              }
            >
              <SaveIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>
        </Grid>
      )}

      {/* buscar aeronave */}
      <Grid item md={4} xs={12}>
        <TextFieldRG select label="Aeronave" name="aeronaveId">
          {aeronaves?.results?.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.prefixo}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item md={4} xs={12}>
        <TextFieldRG type="date" label="Data de Abastecimento" name="dataAbastecimento" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Litros" name="litros" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldMoney label="Valor Abastecido" name="valorAbastecido" />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextFieldRG label="Observação" name="observacao" multiline rows={3} />
      </Grid>

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
