import {
  Box,
  Modal,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Chip,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getMetasGerais } from 'src/services/Planejamento/FundoNacional/metas';
import { getListarEscala } from 'src/services/Operacional/Ciopaer/escala';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { TableCellBody, TableCellHead } from 'src/components/Table/CustomTableCell';
import { formatarDataExtenso, tratandoData } from 'src/config/utils';

interface Usuario {
  id: string;
  nome: string;
  email: string;
  cpf: string;
  status: string;
  criadoEm: string;
  atualizadoEm: string;
}

interface Tripulacao {
  id: string;
  origem: string;
  comandanteAeronaveId: string;
  horarioEntradaComandanteAeronave: string;
  horarioSaidaComandanteAeronave: string;
  comandanteOpAereaId: string;
  horarioEntradaComandanteOpAereas: string;
  horarioSaidaComandanteOpAereas: string;
  escalaCiopaerId: string;
  criadoEm: string;
  atualizadoEm: string;
  comandanteAeronaveNome: Usuario;
  comandanteOpAreaNome: Usuario;
}

export function VisualizarEscala({
  id,
  open,
  handleClose,
  refetch,
}: {
  id: string;
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
}) {
  const [value, setValue] = useState(0);

  const [tripulacaoAsaFixa, setTripulacaoAsaFixa] = useState<Tripulacao>();
  const [tripulacaoAsaRotativa, setTripulacaoAsaRotativa] = useState<Tripulacao>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: rows, isPending } = useQuery({
    queryKey: ['getEscala', id],
    queryFn: getListarEscala,
  });

  useEffect(() => {
    if (rows) {
      const asaFixa = rows.Tripulacao.filter((item: any) => item.origem === 'Asa Fixa');
      const asaRotativa = rows.Tripulacao.filter((item: any) => item.origem === 'Asa Rotativa');
      setTripulacaoAsaFixa(asaFixa[0]);
      setTripulacaoAsaRotativa(asaRotativa[0]);
    }
  }, [rows]);

  console.log(rows);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right', mt: -4 }}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12} sx={{ mt: 3 }}>
            <CustomContainerList type="detalhes" title={`Escala `} shadow>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead sx={{ textAlign: 'left' }}>Data da Escala</TableCellHead>
                    <TableCellHead sx={{ textAlign: 'left' }}>Criado Por</TableCellHead>
                    <TableCellHead sx={{ textAlign: 'left' }}>Criado Em</TableCellHead>
                    <TableCellHead>Situação</TableCellHead>
                  </TableRow>
                  <TableRow>
                    <TableCellBody>
                      {formatarDataExtenso(rows?.DadosEscala.dataEscala)}
                    </TableCellBody>
                    <TableCellBody>{rows?.DadosEscala.status}</TableCellBody>e
                  </TableRow>
                  <TableRow>
                    <TableCellHead>TRIPULAÇÃO ASAS ROTATIVAS</TableCellHead>
                  </TableRow>
                </TableHead>
              </Table>
            </CustomContainerList>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
