import { Api } from 'src/services/Api';
import Endpoints from 'src/services/endpoints';

// GET
export async function getEmpresasAbastecimentos({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search] = queryKey;
  const filters = { search };
  const response = await Api.get(`${Endpoints.abastecimento}/empresa-abastecimento`, {
    params: {
      skip,
      take,
      filters,
    },
  });
  return response.data;
}
export async function getCombustiveisAbastecimentos({ queryKey }: { queryKey: any }) {
  const [_key, empresaId] = queryKey;
  const response = await Api.get(
    `${Endpoints.abastecimento}/combustivel-abastecimento/${empresaId}`
  );
  return response.data;
}
export async function getAbastecimentos({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.abastecimento, {
    params: {
      skip,
      take,
      filters,
    },
  });
  return response.data;
}
export async function criarAbastecimentos(dadosAeronave: any) {
  const response = await Api.post(Endpoints.abastecimento, {
    ...dadosAeronave,
  });
  return response.data;
}
export async function atualizarAbastecimentos(dadosAeronave: any) {
  const response = await Api.patch(`${Endpoints.abastecimento}/${dadosAeronave.id}`, {
    ...dadosAeronave,
  });
  return response.data;
}

export async function getAbastecimento({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.abastecimento}/${id}`);
  return response.data;
}
export async function criarEmpresaAbastecimentos(dadosAeronave: any) {
  const response = await Api.post(`${Endpoints.abastecimento}/empresa-abastecimento`, {
    ...dadosAeronave,
  });
  return response.data;
}
export async function criarCombustivelAbastecimentos(dadosAeronave: any) {
  const response = await Api.post(`${Endpoints.abastecimento}/combustivel-abastecimento`, {
    ...dadosAeronave,
  });
  return response.data;
}
