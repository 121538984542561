import { Api } from '../Api';
import Endpoints from '../endpoints';

interface DadosUsuario {
  id: string;
  permissao: {
    nome: string;
  }[];
}

export async function InserirPermissaoUsuario(dadosUsuario: DadosUsuario) {
  const response = await Api.patch(`${Endpoints.usuarioPermissao}/${dadosUsuario.id}`, {
    permissao: dadosUsuario.permissao,
  });
  return response.data;
}

export async function patchAtualizarUsuario(dadosUsuario: any) {
  const response = await Api.patch(`${Endpoints.usuario}/${dadosUsuario.id}`, {
    dadosUsuario,
  });
  return response.data;
}

export async function GrupoPermissoes(dadosGrupo: any) {
  const response = await Api.patch(`${Endpoints.grupoPermissoes}/${dadosGrupo.id}`, {
    ...dadosGrupo,
  });
  return response.data;
}
