import { Api } from 'src/services/Api';
import Endpoints from 'src/services/endpoints';

export async function criarEscala(dadosEscala: any) {
  const response = await Api.post(Endpoints.escalaCiopaer, {
    ...dadosEscala,
  });
  return response.data;
}
export async function getListarEscalas() {
  const response = await Api.get(Endpoints.escalaCiopaer);
  return response.data;
}

export async function getListarEscala({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.escalaCiopaer}/${id}`);
  return response.data;
}

interface DadosAutorizacao {
  id: string;
  usuarioAuthorizationId: string;
}

export async function AutorizarEscala(dadosAutorizacao: DadosAutorizacao) {
  const response = await Api.patch(`${Endpoints.escalaCiopaer}/autorizar/${dadosAutorizacao.id}`, {
    usuarioAuthorizationId: dadosAutorizacao.usuarioAuthorizationId,
  });
  return response.data;
}
