import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { RouteProtect } from 'src/Auth/RouteProtect';
import MenuCards from 'src/pages/CardMenu';
import Login from 'src/pages/Login';

import { PageError } from 'src/pages/PageErro';
import { Planejamento } from './Planejamento';
import { Admin } from './Admin';
import { Operacional } from './Operacional';

const router = createBrowserRouter([
  {
    path: '*',
    element: <PageError />,
  },
  {
    element: <Login />,
    path: '/',
  },
  {
    element: <RouteProtect element={<MenuCards />} />,
    path: '/menu',
  },

  // ADMIN
  Admin,
  // PLANEJAMENTO
  Planejamento,
  // CIOPAER
  Operacional,
]);

export default router;
