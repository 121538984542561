import { Api } from 'src/services/Api';
import Endpoints from 'src/services/endpoints';

// GET
export async function getAeronaves({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.aeronaves, {
    params: {
      skip,
      take,
      filters,
    },
  });
  return response.data;
}
export async function getAeronave({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.aeronaves}/${id}`);
  return response.data;
}

export async function criarAeronave(dadosAeronave: any) {
  const response = await Api.post(Endpoints.aeronaves, {
    ...dadosAeronave,
  });
  return response.data;
}
export async function atualizarAeronave(dadosAeronave: any) {
  const response = await Api.patch(`${Endpoints.aeronaves}/${dadosAeronave.id}`, {
    ...dadosAeronave,
  });
  return response.data;
}
