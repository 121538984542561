import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';

interface ItemListProps {
  title: string;
  Icon: JSX.Element;
  select: boolean;
  link: string;
  open: boolean;
  children?: Array<{ title: string; icon: JSX.Element; link: string }>;
}

const ItemList: React.FC<ItemListProps> = ({ title, Icon, select, link, open, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const subMod = pathSegments[2];

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {children ? (
        <>
          {/* Item com filhos */}
          <ListItem key={title} disablePadding sx={{ display: 'block', px: open ? 2 : 0.3 }}>
            <ListItemButton
              selected={select}
              onClick={handleClick}
              sx={{
                justifyContent: 'center',
                borderRadius: 1,
                '&.MuiListItemButton-root': {
                  color: '#626262',
                },
                '&.MuiListItemButton-root.Mui-selected': {
                  background: '#019362',
                  color: 'white',
                },

                '&.MuiListItemButton-root.Mui-selected .MuiListItemIcon-root': {
                  color: 'white',
                },
                '&.MuiListItemButton-root.Mui-selected .MuiTypography-root': {
                  fontWeight: 700,
                },
                '&.MuiListItemButton-root:hover': {
                  background: '#016e49',
                  color: 'white',
                },
                '&.MuiListItemButton-root:hover .MuiListItemIcon-root': {
                  color: 'white',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  color: '#626262',
                  ml: open ? 0 : 3,
                }}
              >
                {Icon}
              </ListItemIcon>
              <ListItemText
                primary={title}
                sx={{
                  fontSize: '10px',
                  '&.MuiListItemText-root .MuiTypography-root': {
                    fontWeight: 700,
                  },
                }}
              />
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>

          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ px: !open ? 0 : 2, mt: -1 }}>
              {children.map((child, index) => (
                <ItemList
                  key={index}
                  title={child.title}
                  Icon={child.icon}
                  link={child.link}
                  select={
                    subMod ===
                    child.title
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f\s]/g, '')
                      .toLocaleLowerCase()
                  }
                  open={false}
                />
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <ListItem key={title} disablePadding sx={{ display: 'block', my: 2, px: open ? 2 : 0.3 }}>
          <ListItemButton
            className="listMenu"
            href={link}
            selected={select}
            sx={{
              justifyContent: 'center',
              borderRadius: 1,
              '&.MuiListItemButton-root': {
                color: '#626262',
              },
              '&.MuiListItemButton-root.Mui-selected': {
                background: 'none',
                boxShadow: '10px 10px 150px #019362 inset',
                color: 'white',
              },
              '&.MuiListItemButton-root.Mui-selected .MuiListItemIcon-root': {
                color: 'white',
              },
              '&.MuiListItemButton-root.Mui-selected .MuiTypography-root': {
                fontWeight: 700,
              },
              '&.MuiListItemButton-root:hover': {
                background: '#016e49',
                color: 'white',
              },
              '&.MuiListItemButton-root:hover .MuiListItemIcon-root': {
                color: 'white',
              },
            }}
          >
            <ListItemIcon
              sx={{
                justifyContent: 'center',
                color: '#626262',
              }}
            >
              {Icon}
            </ListItemIcon>
            <ListItemText
              primary={title}
              sx={{
                fontSize: '10px',
                '&.MuiListItemText-root .MuiTypography-root': {
                  fontWeight: 700,
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      )}
    </>
  );
};

export default ItemList;
