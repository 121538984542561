import axios from 'axios';
import { Api } from '../Api';
import Endpoints from '../endpoints';

// ADMIN
export async function getUsuarios({ queryKey }: { queryKey: any }) {
  const [_key, skip, take] = queryKey;
  const response = await Api.get(Endpoints.usuario, {
    params: {
      skip,
      take,
    },
  });
  return response.data;
}

export async function getUsuario(id: string) {
  const response = await Api.get(`${Endpoints.usuario}/${id}`);
  return response.data;
}

export async function getInstituicoes() {
  const response = await Api.get(Endpoints.instituicao);
  return response.data;
}

export async function getUnidadesInstituicao({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.unidade}/${id}`);

  return response.data;
}

export async function getUnidades() {
  const response = await Api.get(`${Endpoints.unidade}`);

  return response.data;
}

export async function getGrupoPermissoes() {
  const response = await Api.get(`${Endpoints.grupoPermissoes}`);

  return response.data;
}
export async function getGrupoPermissoesID({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;

  const response = await Api.get(`${Endpoints.grupoPermissoes}/${id}`);

  return response.data;
}

// DESPESAS
export async function getTipoDespesas({ queryKey }: { queryKey: any }) {
  const [_key, skip, take] = queryKey;
  const response = await Api.get(Endpoints.tipoDespesa, { params: { skip, take } });
  return response.data;
}
// DESPESAS

export async function getFlagsPermissoes() {
  const response = await Api.get(`${Endpoints.flagsPermissoes}`);
  return response.data;
}
