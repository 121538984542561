import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { getAbastecimentos } from 'src/services/Operacional/Ciopaer/abastecimentos';
import { useQuery } from '@tanstack/react-query';
import { valorMonetario, tratandoData, tratandoValorCentavos } from 'src/config/utils';
import CadastroAeronave from '../Cadastro/aeronave';
import CadastroAbastecimento from '../Cadastro/abastecimento';
import AtualizarAbastecimento from '../Atualizar/abastecimento';

export default function Abastecimentos() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const {
    data: rows,
    refetch: RefecthAbastecimento,
    isPending,
  } = useQuery({
    queryKey: ['getAbastecimentos', skip, take, search],
    queryFn: getAbastecimentos,
  });
  const handleButtonClick = (idAeronave: string, type: string) => {
    setId(idAeronave);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'aeronave',
      headerName: 'AERONAVE',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'empresa',
      headerName: 'EMPRESA',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'combustivel',
      headerName: 'COMBUSTÍVEL',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'litros',
      headerName: 'LITROS',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dataAbastecimento',
      headerName: 'DATA DO ABASTECIMENTO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.dataAbastecimento),
    },

    {
      field: 'valorAbastecido',
      headerName: 'VALOR DO ABASTECIMENTO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valorAbastecido)}</>,
    },
    {
      field: 'observacao',
      headerName: 'OBSERVAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton color="warning" onClick={() => handleButtonClick(params.row.id, 'Editar')}>
            <ModeEditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Abastecimentos
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Abastecimento" backgroundAlert="#FF8E38">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
        {modalType === 'Cadastro' && (
          <CadastroAbastecimento
            open={isModalOpen}
            handleClose={handleCloseModal}
            RefecthAbastecimento={RefecthAbastecimento}
          />
        )}
        {modalType === 'Editar' && id && (
          <AtualizarAbastecimento
            open={isModalOpen}
            handleClose={handleCloseModal}
            id={id}
            RefecthAbastecimento={RefecthAbastecimento}
          />
        )}
      </Stack>
    </Box>
  );
}
