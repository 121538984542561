import * as yup from 'yup';

interface Permissoes {
  funcao: string;
  modulo: string;
}

export interface dadosUsuario {
  nome: string;
  email: string;
  senha: string;
  permissoes: Permissoes[];
  permissoesUsuario: object[];
}

export const schemaUsuario = yup.object<dadosUsuario>({
  nome: yup.string().required('Nome é obrigatório'),
  email: yup.string().required('E-mail é obrigatório'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform((value: any) => {
      const limpandoString = value.replace(/[.-]/g, '');
      return limpandoString;
    }),
  senha: yup.string(),
  permissoes: yup.array().of(
    yup.object().shape({
      funcao: yup.string().required('Função é obrigatória'),
      modulo: yup.string(),
    })
  ),
  nomeGuerra: yup.string(),
  codigoAnac: yup.string(),
  horasInicias: yup.number(),
  funcao: yup.string(),
});
export const schemaAltUsuario = yup.object<dadosUsuario>({
  nome: yup.string().required('Nome é obrigatório'),
  email: yup.string().required('E-mail é obrigatório'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform((value: any) => {
      const limpandoString = value.replace(/[.-]/g, '');
      return limpandoString;
    }),
  senha: yup.string(),
  permissoes: yup.array().of(
    yup.object().shape({
      funcao: yup.string().required('Função é obrigatória'),
      modulo: yup.string(),
    })
  ),
  nomeGuerra: yup.string(),
  codigoAnac: yup.string(),
  horasInicias: yup.number(),
  funcao: yup.string(),
});

export const defaultValuesUsuario = {
  nome: '',
  email: '',
  senha: '',
  permissoes: [],
};
