import DashboardIcon from '@mui/icons-material/Dashboard';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import PeopleIcon from '@mui/icons-material/People';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import HandymanIcon from '@mui/icons-material/Handyman';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LockIcon from '@mui/icons-material/Lock';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { PriceChange } from '@mui/icons-material';

export interface Module {
  title: string;
  link: string;
  icon: JSX.Element;
  permissao?: string;
  children?: any;
}

export const administrador: Module[] = [
  {
    title: 'Usuários',
    link: '/administrador/usuarios',
    icon: <PeopleIcon />,
  },
  {
    title: 'Unidades',
    link: '/administrador/unidades',
    icon: <AddHomeIcon />,
  },
  {
    title: 'Despesas',
    link: '/administrador/despesas',
    icon: <PriceChange />,
  },
  {
    title: 'Permissões',
    link: '/administrador/permissoes',
    icon: <LockIcon />,
  },

  //   {
  //     title: 'Módulos',
  //     link: 'modulos',
  //     icon: <PeopleIcon />,
  //   },
];
export const planejamento: Module[] = [
  {
    title: 'Dashboard',
    link: '/planejamento/dashboard',
    icon: <DashboardIcon />,
    permissao: 'PLANEJAMENTO',
  },
  //   {
  //     title: 'Notificações',
  //     link: '/planejamento/notificacao',
  //     icon: <NotificationsActiveIcon />,
  //     permissao: 'MODULE_NOTIFICACAO_PLANEJAMENTO',
  //   },
  {
    title: 'Fundo Nacional',
    link: '/planejamento/fundonacional',
    icon: <SnippetFolderIcon />,
    permissao: 'FUNDO_NACIONAL',
  },
  //   {
  //     title: 'Convênio',
  //     link: '/planejamento/convenio',
  //     icon: <SubjectIcon />,
  //     permissao: 'CONVENIO',
  //   },
];
export const operacional: Module[] = [
  {
    title: 'Dashboard',
    link: '/operacional/dashboard',
    icon: <DashboardIcon />,
    permissao: 'OPERACIONAL',
  },
  {
    title: 'Ciopaer',
    link: '/operacional/ciopaer',
    icon: <AirplanemodeActiveIcon />,
    permissao: 'CIOPAER',
    children: [
      {
        title: 'Agenda',
        icon: <CalendarMonthIcon />,
        link: '/operacional/ciopaer/agenda',
      },
      {
        title: 'Aeronaves',
        icon: <ConnectingAirportsIcon />,
        link: '/operacional/ciopaer/aeronaves',
      },
      {
        title: 'Escalas',
        icon: <PermContactCalendarIcon />,
        link: '/operacional/ciopaer/escalas',
      },
      {
        title: 'Manutenção',
        icon: <HandymanIcon />,
        link: '/operacional/ciopaer/manutencao',
      },
      {
        title: 'Ocorrências',
        icon: <NotificationImportantIcon />,
        link: '/operacional/ciopaer/ocorrencias',
      },
      {
        title: 'Relatórios',
        icon: <DescriptionIcon />,
        link: '/operacional/ciopaer/relatorio',
      },
      {
        title: 'Servidores',
        icon: <PeopleIcon />,
        link: '/operacional/ciopaer/servidores',
      },
    ],
  },
];
